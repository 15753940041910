.contact__container{
    grid-template-columns: 4fr 8fr;
}

.contact__title{
    font-size: var(--h3-font-size);
    font-weight: var(--weight-600);
    margin-bottom: 1rem;
}

.contact__description{
    font-size: var(--small-font-size);
    line-height: 1.6;
}

.contact__description,
.contact__info .info__item{
    margin-bottom: 20px;
}

.contact__info .info__item{
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.contact__info .info__icon{
    color: var(--first-color);
    font-size: var(--h2-font-size);
}

.contact__info .info__title{
    font-size: var(--small-font-size);
}

.contact__info .info__desc{
    font-family: var(--body-font);
    font-size: var(--small-font-size);
    font-weight: var(--weight-500);
}

.contact__socials{
    display: flex;
    column-gap: 16px;
}

.contact__social-link{
    background-color: var(--container-color);
    color: var(--title-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 44px;
    transition: all 0.3s ease;
}

.contact__social-link:hover{
    background-color: var(--first-color);
    color: var(--text-white);
}

.form__input-group{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
}

.form__input-div{
    margin-bottom: 30px;
}

.form__control{
    border: 1px solid var(--container-color);
    background-color: var(--container-color);
    color: var(--title-color);
    width: 100%;
    padding: 12px 24px;
    border-radius: 30px;
    font-size: var(--small-font-size);
    transition: all 0.3s ease;
}

.form__control:focus{
    border-color: var(--first-color);
}

.textarea{
    resize: none;
    height: 160px;
}

.contact__button-icon{
    font-size: var(--h3-font-size);
    line-height: 64px;
}

/* Media query for mobile devices */
@media (max-width: 480px) {
    .grid{
        display: block;
    }
    
    .form__input-group {
      grid-template-columns: 1fr; /* Change to a single column layout */
      margin-top: 10px;
    }

    .button{
        margin-left: 50px;
    }
  
    .form__input-div {
      margin-bottom: 20px; /* Reduce margin for better spacing */
    }
  
    .contact__button {
      text-align: center; /* Center-align the button */
    }
  }